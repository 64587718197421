angular.module('myApp').controller('GallerySliderCtrl',['$scope', '$element', '$timeout', function ($scope, $element, $timeout) {
    var containerItems = $($element).find('.b-gallery-slider__items'),
        containerScale = $($element).find('.b-gallery-slider__scale');

    $scope.index = 0;
    // делаем уши

    $timeout(function(){
        containerItems.find('.b-gallery-slider__item:last').prependTo(containerItems);
        containerItems.find('.b-gallery-slider__item:last').prependTo(containerItems);    
        $scope.init();
    }, 0);
    

    $scope.init = function(){
        var items = containerItems.find('.b-gallery-slider__item'),
            current = $(items[2]),
            currentImg = current.find('.b-gallery-slider__image');

        items.removeClass('b-gallery-slider__item_current');
        current.addClass('b-gallery-slider__item_current');

        $scope.collapseHeight();

        // fix chrome bug with resize
        items.hide().show(0);

        // позиционируем с учетом ушей
        containerItems
            .css({left: -(current.position().left - containerScale.width() / 2 + current.width() / 2)});
    }

    $scope.collapseHeight = function(){
        if($(window).width() < 768) {
            containerScale.height($('.b-gallery-slider__item_current .b-gallery-slider__image').height());
        } else {
            containerScale.height('');
        }
    }

    $scope.next = function() {
        if(!$scope.isProcess) {
            if ($scope.index == $scope.count - 1) {
                $scope.index = 0;
            } else {
                $scope.index++ ;
            };

            $scope.isProcess = true;

            var items = containerItems.find('.b-gallery-slider__item'),
                future = $(items[3]),
                futureImg = future.find('.b-gallery-slider__image');

            containerItems
                .animate({left: -(future.position().left - containerScale.width() / 2 + futureImg.width() / 2)}, 500, function(){
                    containerItems
                        .find('.b-gallery-slider__item:first')
                        .appendTo(containerItems)
                        .parent()
                        .css({left: -(future.position().left - containerScale.width() / 2 + futureImg.width() / 2)});    

                    $scope.isProcess = false;
                });

            items.removeClass('b-gallery-slider__item_current');
            future.addClass('b-gallery-slider__item_current');

            $scope.collapseHeight();
        }
    };
    
    $scope.prev = function() {
        if(!$scope.isProcess) {
            if ($scope.index == 0) {
                $scope.index = $scope.count - 1;
            } else {
                $scope.index--;
            };

            $scope.isProcess = true;

            var items = containerItems.find('.b-gallery-slider__item'),
                future = $(items[1]),
                futureImg = future.find('.b-gallery-slider__image');

            containerItems
                .animate({left: -(future.position().left - containerScale.width() / 2 + futureImg.width() / 2)}, 500, function(){
                    containerItems.find('.b-gallery-slider__item:last')
                    .prependTo(containerItems)
                    .parent()
                    .css({left: -(future.position().left - containerScale.width() / 2 + futureImg.width() / 2)})
                    $scope.isProcess = false;
                });
                
            items.removeClass('b-gallery-slider__item_current');
            future.addClass('b-gallery-slider__item_current');

            $scope.collapseHeight();
        }
    };

    $(window).resize(function(){
        $scope.init();
    })

    $('.b-gallery-slider__image').load(function(){
        $scope.init();
    });
}]);