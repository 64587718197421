angular.module('myApp').controller('galleryAdvCtrl',['$scope','$element','$timeout', function ($scope,$element,$timeout) {

    $scope.preview = {
        index : 0,
        count: 0,
        size: 7,
        next: function() {
            if(this.index > this.count - (this.size + 1)) {
                this.index = 0;
            } else {
                this.index++;
            }
        },
        prev: function() {
            if(this.index == 0) {
                this.index = this.count - this.size;
            } else {
                this.index--;
            }
        }
    }

    $scope.slider = {
        index: 0,
        count: 0,
        navigation: {
            top: 0,
            fixed: false
        },
        next: function () {
            if (this.index == this.count -1) {
                this.index = 0;
            }else{
                this.index++;
            };

            scrollToTop();
        },
        prev: function () {
            if (this.index == 0) {
                this.index = this.count -1;
            }else{
                this.index--;
            };

            scrollToTop();
        },
        setIndex: function(index) {
            this.index = index;
        }
    };

    $scope.$watch('slider.count', function(v){
        $scope.preview.count = v;
    });

    $(window).scroll(navigationFloat);
    $(window).resize(function(){
        navigationFloat();
    });

    var nav = $element.find('.navigation_slider');

    function navigationFloat(){
        var scrollTop = $(window).scrollTop(),
            slideMiddle = nav.offset().top + nav.innerHeight() / 2,
            slideBottom = $element.offset().top + $element.innerHeight();

        $scope.slider.navigation.fixed = (scrollTop >= slideMiddle && scrollTop <= slideBottom);

        try {
            $scope.$digest();
        }
        catch(e) {

        }
    }

    function scrollToTop() {
        $(window).scrollTop($($element).offset().top);
    }

    navigationFloat();
}]);