/**
 * Состояние списков
 */
angular.module('myApp').provider('ListState', [function() {

    var state = {
        page: 0,
        scrollTop: 0
    };

    return {
        state: state,
        $get: ['$rootScope', 'Page', function($rootScope, Page) {
            return {
                setPage: function(page) {
                    state.page = page;
                },
                setScrollTop: function(scrollTop) {
                    state.scrollTop = scrollTop;
                },
                getScrollTop: function() {
                    return state.scrollTop;
                },
                reset: function() {
                    state.page = 0;
                    state.scrollTop = 0;
                },
                needReset: function(prevType, nextType) {
                    if (!prevType) {
                        return true;
                    }

                    // Если мы переходим со списка на список - сбрасываем
                    if (Page.isList(prevType) && Page.isList(nextType)) {
                        return true;
                    }

                    // Если со страницы на страницу - сбрасываем
                    if (Page.isSingle(prevType) && Page.isSingle(nextType)) {
                        return true;
                    }

                    // В случае, если переходим со списка на страницу
                    // или со списка на страницу - не сбрасываем
                    return false;
                }

            }
        }]
    };

}]);