angular.module('myApp').controller('sliderCtrl',['$scope','$element','$timeout', function ($scope,$element,$timeout) {

    var slider  = $element[0],
        ul      = slider.getElementsByTagName('ul')[0],
        images  = slider.getElementsByClassName('js-slide-item'),
        counter = slider.getElementsByClassName('js-slider-counter'),
        size    = {};


    $scope.current = 0 ;
    $scope.count =  images.length-1;

    $scope.next = function () {
        move(+1);
    };

    $scope.prev = function () {
        move(-1);
    };

    var getSize = function () {
            size = slider.getBoundingClientRect();
        },

        createDot = function () {
            if (counter.length > 0) {
                Array.prototype.forEach.call(images, function (val,key) {
                    var btn = document.createElement("a");
                    angular.element(btn).on('click' , function () {
                        doMove(key);
                    });

                    angular.element(counter).append(btn);
                });
                activeClass('add');
            }
        },

        setSlideSize = function () {
            ul.style.width   = images.length * size.width  + 'px';
            ul.style.height  = '100%';

            Array.prototype.forEach.call(images, function (image) {
                image.style.width  = size.width + 'px';
                image.style.height = size.height + 'px';
            });
        },

        setTransform = function(element,css) {
            element.style.webkitTransform = css;
            element.style.MozTransform = css;
            element.style.msTransform = css;
            element.style.OTransform = css;
            element.style.transform = css;
        },

        activeClass = function (func){
            if (counter[0]) {
                var current =  counter[0].getElementsByTagName('a')[$scope.current];
                if (counter.length > 0 && current) {
                    current.classList[func]('active');
                }
            }
        },

        doMove = function (pos) {
            setTransform(ul,' translate(' + -(pos * size.width) + 'px, 0px) ');
            activeClass('remove');
            $scope.current = pos;
            activeClass('add')
        },

        move = function  (step) {
            var current = ($scope.current + step);
            current = current > $scope.count ? 0 : (current<0) ? $scope.count  : current;
            doMove(current);
            $timeout.cancel(timer);
        };


    var timer;

    var sliderStep = function() {
        timer = $timeout(function() {
            $scope.next();
            timer = $timeout(sliderStep, 5000);
        }, 5000);
    };

    $scope.autoScrollOff = function () {
        $timeout.cancel(timer);
    };

    $scope.autoScrollOn = function() {
        sliderStep();
    };

    $scope.$on('$destroy',function(){
        $timeout.cancel(timer);
    });

    getSize();
    setSlideSize();
    createDot();

    // resize
    var initRes;
    angular.element(window).on('resize', function () {
        clearTimeout(initRes);
        initRes = setTimeout(function () {
            getSize();
            setSlideSize();
            doMove(0);
        },300);

    })

}]);