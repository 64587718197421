angular.module('myApp').controller('Main', [
'$scope','$location', '$route', '$timeout', '$rootScope', '$http', '$window', '$templateCache', 'News', 'Footer', 'Search', 'ListState', 'Page',
function($scope, $location, $route, $timeout, $rootScope, $http, $window, $templateCache, News, Footer, Search, ListState, Page) {
    var scrollBegin, articleEnd, footerSeen;
    if (moment.hasOwnProperty('locale')) {
        moment.locale('ru');
    }
    var tmpScrollTop;
    $scope.$location = $location;
    var banners = window.banners
        ? window.banners
        : [];

    $scope.news = {
        list: [],
        load: false,
        hasMore: true
    };
    $scope.pageNews = {};

    $scope.newsLoad = false;
    $scope.footerHtml = '';
    $scope.needToScrollTop = false;
    $scope.isSectionsHide = false;
    $scope.isFooterHide = false;

    var counterTransitions = 0;

    $scope.search = {
        short: '',
        full: ''
    };

    $scope.searchResult = {
        short: [],
        full: []
    };

    // Предыдущая страница списка
    $scope.prevListPage = '';

    $scope.more =false;
    $scope.showMore = function() {
        $scope.more = true;
    };

    // menu
    $scope.menuIsOpen = false;
    $scope.toggleMenu = function (val) {
        if (typeof val === 'boolean') {
            $scope.menuIsOpen = val;
        } else {
            $scope.menuIsOpen = !$scope.menuIsOpen;
        }
    };

    $scope.$back = function() {
        window.history.back();
    };

    /**
     * Обработка получение списка новостей
     */
    $scope.loadNews = function() {
        $scope.news.load = true;

        var lastTime = $('[data-timestamp]').last().data('timestamp');

        News.list(lastTime).then(function(data) {
            angular.forEach(data.data, $scope.mergeNewsDay);
            $scope.news.hasMore = data.hasMore;
        }).finally(function() {
            $scope.news.load = false;
        });
    };

    /**
     * Сброс списка новостей
     */
    $scope.resetNewsList = function() {
        $scope.news = {
            list: [],
            load: false,
            hasMore: true
        };
    };

    /**
     * Смержим дни новостей
     *
     * @param day
     */
    $scope.mergeNewsDay = function(day) {
        var findedDay = _.findWhere($scope.news.list, {timestamp: day.timestamp});
        if (!findedDay) {
            $scope.news.list.push(day);
        } else {
            findedDay.data = findedDay.data.concat(day.data);
        }
    };

    /**
     * Получаем заголовок дня
     *
     * @param time
     */
    $scope.getDayTitle = function(time) {
        time = moment.unix(time);
        var title = time.format('D MMMM');
        if (moment().isSame(time, 'day')) {
            title = 'Сегодня ' + time.format('dddd') + ', ' + title;
        }

        return title;
    };

    /**
     * Получаем время для новости
     *
     * @param time
     * @returns {*}
     */
    $scope.getNewsTime = function(time) {
        return moment.unix(time).format('HH:mm');
    };

    /**
     * Подгрузка футера для текущего URL
     *
     * @param params
     */
    $scope.loadFooterForUrl = function(params) {
        Footer.load(params).then(function(data) {
            if (data.success) {
                $scope.footerHtml = data.data;
            }
        });
    };

    /**
     * Поиск по маленькой строке запроса
     */
    $scope.searchShort = function() {
        $scope.search.full = '';
        $scope.searchResult.full = [];

        if ($scope.search.short.length < 3) {
            $scope.searchResult.short = [];
        } else {
            Search.get($scope.search.short).then(function(data) {
                for(var i=0; i < data.data.length; i++) {
                    data.data[i].published_at = isFinite(data.data[i].published_at)
                        ? moment.unix(data.data[i].published_at).format('DD MMMM YYYY, HH:mm')
                        : moment(data.data[i].published_at).format('DD MMMM YYYY, HH:mm');
                }
                if ($rootScope.isShowSearchForm){
                    $scope.searchResult.short = data.data;
                }
            });
        }
    };

    /**
     * Поиск по большой строке запроса
     */
    $scope.searchFull = function() {
        $scope.search.short = '';
        $scope.searchResult.short = [];

        if ($scope.search.full.length < 3) {
            $scope.searchResult.full = [];
        } else {
            Search.get($scope.search.full).then(function(data) {
                $scope.searchResult.full = data.data;
            });
        }
    };

    $scope.searchKeyPress = function($event) {
        if($event.which == 13) {
            $timeout(function(){
                $scope.goSearch();
            }, 500);
        }
    };

    $scope.goSearch = function() {
        $location.path('/search/' + $scope.search.short);
        $scope.resetSearch();
        $rootScope.isShowSearchForm = false;
    }

    /**
     * Сброс поиска
     */
    $scope.resetSearch = function() {
        $scope.search.short = '';
        $scope.search.full = '';
        $scope.searchResult.short = [];
        $scope.searchResult.full = [];
    };

    $rootScope.$watch('isShowSearchForm', function(v) {
        if(v) {
            $timeout(function (argument) {
                $('.b-search__input').focus();
            },100)
        }
    });

    /**
     * Является ли текущий браузер firefox'ом
     *
     * @returns {boolean}
     */
    $scope.isFirefox = function() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    };

    /**
     * На первой ли мы странице
     *
     * @returns {Object|boolean|*}
     */
    $scope.isFirstPage = function() {
        return counterTransitions < 2;
    };

    $scope.openSearch = function(src) {
        if ($scope.search[src]) {
            $location.path('/search/' + $scope.search[src]);
        }
    };

    // Подпишемся на клик по поиску
    var closeSearchResultHelp = function(e) {
        if (!$scope.isFirefox() && e.relatedTarget && $(e.relatedTarget).hasClass('suggest')) {
            return true;
        } else if ($scope.isFirefox() && e.explicitOriginalTarget && $(e.explicitOriginalTarget.parentElement).hasClass('suggest')){
            return true;
        }

        // боремся с сафари
        $timeout(function(){
            $scope.searchResultHelp = false;
            $scope.searchTextFocus = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }, 100, false)
    };

    $('.b-search-full, .b-search').each(function(i, element) {
        element.addEventListener('blur', closeSearchResultHelp, true);
    });

    $scope.$on('$routeChangeStart', function(next, current) {
        $scope.toggleMenu(false);
        //обнуляем счетчики запросов как новая страница
        scrollBegin = false; // начали скролить
        articleEnd = false; // статья закончилась
        footerSeen = false; // увидели футер
        var footerData = detectFooterDataFromRoute(current);

        $scope.loadFooterForUrl(footerData);

        //Данные футера вполне подходят для определения местонахождения
        $scope.needToScrollTop = footerData.type === Footer.TYPE_ARTICLE;
    });

    /**
     * Обработка смены состояния для сохранения позиции списка
     */
    $scope.$on('$routeChangeStart', function(e, next, prev) {
        if (typeof prev !== 'undefined') {
            $templateCache.remove(prev.loadedTemplateUrl);
        }

        var prevType = prev ? prev.type : null;
        var nextType = next ? next.type : null;

        if (ListState.needReset(prevType, nextType)) {
            ListState.reset();
        }

        if (Page.isList(nextType)) {
            if ($scope.prevListPage != $location.url()) {
                ListState.reset();
            }

            $scope.prevListPage = $location.url();
            tmpScrollTop = ListState.getScrollTop();
        }

        if (Page.isNews(nextType)) {
            $scope.resetNewsList();
            $scope.loadNews();
        }

        $scope.isShowSlider = !(!window.isMainPage && !prevType);
    });

    /**
     * Обработка завершения смены состояния
     */
    $scope.$on('$routeChangeSuccess', function(e, current) {
        if (Page.isList(current.type)) {
            if ($scope.isFirefox()) {
                $timeout(function() {
                    $(window).scrollTop(tmpScrollTop);
                }, 500);
            } else {
                $(window).scrollTop(tmpScrollTop);
            }
        }

        $scope.isSinglePage = Page.isSingle(current.type);
        $scope.isSectionsHide = Page.isSearch(current.type) || Page.isEmailVerificationSuccess(current.type);
        $scope.isFooterHide = Page.isEmailVerificationSuccess(current.type);

        $rootScope.firstPersons.init(false, 'main');

        $rootScope.pageIsSingle = Page.isSingle(current.type);
        $rootScope.pageIsMain = Page.isMain(current.type);
        $rootScope.href = $window.location.href;

        // убираем состояние меню в сайдбаре и фиксированном
        $('.links__item_open .sublinks').slideToggle(300);
        $('.links__item_open').removeClass('links__item_open');

    });
    /**
     * Подпишемся на событие загрузки контента
     * @param route
     * @returns {*}
     */
    $scope.$on('$viewContentLoaded', function() {
        $scope.pageNews.minHeight = $('.b-top3').outerHeight();
        if(!$rootScope.DFP.adsInit){ 
            $rootScope.DFP.init();
        } else {
            $rootScope.DFP.refresh();
        }
        counterTransitions++;
    });

    function detectFooterDataFromRoute(route) {
        if (route.params.section) {
            return {
                type: Footer.TYPE_SECTION,
                slug: route.params.section
            };
        }

        if (route.params.slug) {
            return {
                type: Footer.TYPE_TAG,
                slug: route.params.slug
            };
        }

        if (route.params.tag && route.params.article) {
            return {
                type: Footer.TYPE_ARTICLE,
                slug: route.params.article
            };
        }

        if (!route.$$route || route.$$route.originalPath === '/') {
            return {
                type: Footer.TYPE_MAIN,
                slug: ''
            };
        }

        return {
            type: Footer.TYPE_DEFAULT,
            slug: ''
        };
    }
    //Флаги для GA
    $scope.gaSendPush = function(param) {
        ga('send', 'event', 'page', 'scroll', param);
    }

    // Подпишемся на скролл страницы
    $(window).on('scroll', function(e) {
        var footer = $('footer');

        $scope.scrollTop = $(window).scrollTop();

        if (!scrollBegin && $(window).scrollTop() >150) {
            scrollBegin = true;
            $scope.gaSendPush('scrollBegin');
        };
        if (footer.length && !footerSeen && $(window).scrollTop() > footer.offset().top -$(window).innerHeight()+50) {
            footerSeen = true;
            $scope.gaSendPush('footerSeen');
        };
        if ($('.articleGA').length && !articleEnd && $(window).scrollTop() >$('.articleGA').offset().top + $('.articleGA').innerHeight() - $(window).innerHeight()) {
            articleEnd = true;
            $scope.gaSendPush('articleEnd');
        };

        if (Page.isList($route.current.type)) {
            ListState.setScrollTop($(window).scrollTop());
        }

        if($('.b-page-news').length) {
            $scope.pageNews.isScrolled = $(window).scrollTop()
                >= $('.b-page-news__content').offset().top - 60;
            $scope.pageNews.isStickedBottom = $(window).scrollTop()
                >= $('.b-page-news__content').offset().top - 60 + $('.b-page-news__content').outerHeight() - $('.b-top3').outerHeight();
        }

        if(!Page.isSearch($route.current.type)) {
            $scope.buttonGoUp.bottom = $scope.buttonGoUp.isSticked ? footer.outerHeight() : 0;
            $scope.buttonGoUp.isSticked = $(window).scrollTop() > footer.offset().top -$(window).innerHeight();
            $scope.buttonGoUp.isShow = ($(window).scrollTop() >= $(window).height());
        }

        if(footer.length) {
            if($('.page-is-first-persons').length) {
                $scope.firstPersons.menu.isSticked = $(window).scrollTop() >= footer.offset().top - $(window).innerHeight();
            }
        }

        checkIsFixedHeader();

        fixDateInNews();

        $scope.header.progressbar.position = $(window).scrollTop() / ($('body, html').height() - $(window).height()) * 100;

        $scope.$digest();
    });

    $scope.goUp = function(){
        if(Modernizr.touch) {
            $("html, body").scrollTop(0);
        } else {
            $("html, body").stop().animate({scrollTop:0}, '500');
        }

    };

    window.history.replaceState({firstPage: true}, null, window.location.href);

    $scope.buttonGoUp = {
        isShow: false,
        isSticked: false,
        bottom: 90
    };

    $scope.header = {
        isFixed: false,
        progressbar: {
            position: 0
        }
    };

    $rootScope.firstPersons = {
        enable: false,
        isIndex: false,
        init: function(mode) {
            this.enable = !!mode;
        },
        isInit: function(){
            return this.enable;
        },
        popup: {
            isOpen: false,
            open: function() {
                this.isOpen = true;
            },
            close: function() {
                this.isOpen = false;
            }
        },
        menu : {
            isSticked: false,
            isOpen: false,
            open: function(){
                this.isOpen = true;
            },
            close: function(){
                this.isOpen = false;
            },
            go: function(id) {
                this.close();
                if(Modernizr.touch) {
                    $("html, body").scrollTop($('#' + id).offset().top);
                } else {
                    $("html, body").stop().animate({scrollTop:$('#' + id).offset().top}, '500');
                };
            },
            isActiveLink: function(id) {
                var current = $('#' + id);
                var next = $('#' + id).nextAll('.b-anchor');

                try {
                    return next.length
                        ? $(window).scrollTop() + 1 >= current.offset().top && $(window).scrollTop() + 1 < next.first().offset().top
                        : $(window).scrollTop() + 1 >= current.offset().top;
                } catch(e){}
            }
        }

    };


    $rootScope.unsubscribeForm = {
        isSend: false,
        status: false,
        submit: function (hash, reason) {
            $.post('/unsubscribe/' + hash, {reason: reason, hash: hash})
                .success(function (response) {
                    $rootScope.unsubscribeForm.isSend = true;
                    if (response.success) {
                        $rootScope.unsubscribeForm.status = true;
                    }
                    $rootScope.$apply();
                });
        }
    };

    /** Banners DFP **/
    $rootScope.DFP = {
        adsInit: false,
        statuses: {},
        init: function(){
            $timeout(function(){
                window.yaContextCb.push(null);
                window.yaContextCb.push(function() {
                    for (var i = 0; i < banners.length; i++) {
                        var slug = banners[i].slug;
                        var code = banners[i].code;
                        var id = banners[i].id;
                        var params = banners[i].params;

                        if($('.b-check-visible_slug-' + slug).length && !$rootScope.DFP.statuses[slug].isInit[code]) {
                            $rootScope.DFP.statuses[slug].isInit[code] = true;
                            Ya.adfoxCode.create({
                                ownerId: 255516, 
                                containerId: id, 
                                params: params,
                                onLoad: function(data) { console.info('banner:', code, 'type:', slug, 'load:', data) },
                                onRender: function() { console.info('banner:', code, 'type:', slug, 'onRender'); },
                                onError: function(error) { console.error('banner:', code, 'type:', slug, 'error:', error); },
                                onStub: function() { console.info('banner:', code, 'type:', slug, 'onStub') }
                            });
                        }
                    }
                },0);
            });
            $rootScope.DFP.adsInit= true;
        },
        refresh: function() {
            $timeout(function(){
                Ya.adfoxCode.reload(null, {onlyIfWasVisible: true});
            },0);
        },
        isInit: function(slug){
            for(var key in $rootScope.DFP.statuses) {
                if(key == slug) {
                    return _.keys($rootScope.DFP.statuses[key]).length;
                }
            }
        }
    }
    var points = [0, 768, 1024, 1280];
    var point = -1;

    $(window).on("resize", function(){
        var curPoint = -1;

        for (var i = 0, len = points.length; i < len; i++) {
            if (window.innerWidth >= points[i]) {
                curPoint = points[i];
            };
        };

        if (point != curPoint) {
            point = curPoint;
            $rootScope.DFP.refresh();
        };
    });

    for (var i = 0; i < banners.length; i++) {
        $rootScope.DFP.statuses[banners[i].slug]={'isInit':{}};
    };

    function checkIsFixedHeader(){
        $scope.header.isFixed = !Page.isMain($route.current.type)
            && !$rootScope.firstPersons.enable
            && !$rootScope.firstPersons.isIndex
            && $(window).scrollTop() >= $('.wrap-header_static').offset().top + $('.wrap-header_static').height();
    }

    function fixDateInNews(){
        var scrollTop = $(window).scrollTop(),
            offsetPoint = $('.b-header-fixed').height() + $('.b-page-news__day-bar').height() / 2;

        $('.b-page-news__list .day').each(function(index, element){
            if($(element).offset().top < scrollTop + offsetPoint
                && ($(element).offset().top + $(element).height()) > scrollTop + $('.b-header-fixed').height()) {
                $('.b-page-news__list .day_current').removeClass('day_current');
                $(element).addClass('day_current');
            }
        });

        var pageNewsList = $('.b-page-news__list');

        if(pageNewsList.length > 0) {
            var hasCurrentDay = pageNewsList.offset().top < scrollTop + offsetPoint
                    && (pageNewsList.offset().top + pageNewsList.height()) > scrollTop + offsetPoint;

            if(!hasCurrentDay) {
                $('.b-page-news__list .day_current').removeClass('day_current');
            } else {
                $scope.pageNews.dayIndex = $('.b-page-news__list .day').index($('.b-page-news__list .day_current'));
            }

            $scope.pageNews.hasDayBar = hasCurrentDay;
        }
    }
}]);
