/**
 * Директива для подгрузки статей
 */
angular.module('myApp').directive('loadMore', function() {
    return {
        restrict: 'A',
        scope: {
            pageSize: '=?',
            currentOffset: '=?',
            count: '=',
            url: '@loadMore',
            params: '=?',
            container: '@'
        },
        controller: 'LoadMoreCtrl'
    };
});