/**
 * Сервис для работы с новостями
 */
angular.module('myApp').factory('News', ['$http', '$q', function($http, $q) {

    var emptyData = {
        data: [],
        _meta: {}
    };

    var News = function() {};

    /**
     * Получение списка новостей
     *
     * @param lastTime
     * @returns {*}
     */
    News.list = function(lastTime) {
        var def = $q.defer();

        var query = '/news';
        var params = {lastTime: lastTime, json: true};

        $http.get(query, {params: params}).then(function(resp) {
            if (resp.status !== 200) {
                def.resolve(emptyData);
            } else {
                def.resolve({
                    data: resp.data.data,
                    hasMore: resp.data.hasMore
                });
            }
        }, function() {
            def.resolve(emptyData);
        });

        return def.promise;
    };

    return News;

}]);