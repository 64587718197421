angular.module('myApp').directive('bindCompiledHtml', ['$compile', function($compile) {
    return {
        template: '',
        scope: {
            rawHtml: '=bindCompiledHtml'
        },
        link: function(scope, elem, attrs) {
            scope.$watch('rawHtml', function(value) {
                if (!value) return;

                var newElem = $compile(value)(scope.$parent);

                elem.contents().remove();
                elem.append(newElem);
            });
        }
    };
}]);