angular.module('myApp').controller('TestCtrl', ['$scope', '$q', 'Test', '$attrs', function($scope, $q, Test, $attrs){
	$scope.STATE_PREVIEW = 0;
	$scope.STATE_QUESTIONS = 1;
	$scope.STATE_FORM = 2;
	$scope.STATE_RESULT = 3;

	$scope.id = null;
	$scope.state = $scope.STATE_PREVIEW;
	$scope.current = 0;
	$scope.results = []; // key - question_id, value - answer_id
	$scope.result = {};
	$scope.isConfirm = false;

    angular.extend($scope, $scope.$eval($attrs.testParams));


    var configLoaded = $q.defer();
    if (!$scope.config) {
        Test.config($scope.id).then(function(data) {
            $scope.config = data;
            configLoaded.resolve($scope.config);
        });
    } else {
        configLoaded.resolve($scope.config);
    }

    configLoaded.promise.then(function(config){
        config.length_answers = 0;

        for(var keyQ in config.questions) {
            var question = config.questions[keyQ];

            if(!question.count_answers){
                question.count_answers = 0;
            }

            for(var keyA in question.variants) {
                var answer = question.variants[keyA];
                question.count_answers += +answer.count_answers;
                config.questions[keyQ].variants[keyA].count_answers = +config.questions[keyQ].variants[keyA].count_answers;
            }
        }
    });

	$scope.start = function(){
        configLoaded.promise.then(function(config){
            if(config.questions.length > 0) {
                $scope.state = $scope.STATE_QUESTIONS;
            }
        });
	}

	$scope.next = function(questionId){
		if($scope.results[questionId] != null) {
			var results = [];

			if(angular.isArray($scope.results[questionId])) {
				for(var key in $scope.results[questionId]) {
					results.push({question_id: questionId, answer_id: $scope.results[questionId][key]});
				}
			} else {
				results.push({question_id: questionId, answer_id: $scope.results[questionId]});
			}

			Test.result($scope.id, results).then(function(data){
				if($scope.current < $scope.config.questions.length - 1) {
					$scope.current++;
				} else {
					Test.finish($scope.id).then(function() {
                        $scope.state = $scope.isQuiz
                            ? $scope.STATE_FORM
                            : $scope.STATE_RESULT;
					});
				}
			})
			if (window.innerWidth < 768) {
				$('body').scrollTop($('.wrapper-test').offset().top);
			};
		}
	}

	$scope.restart = function(){
		$scope.state = $scope.STATE_PREVIEW;
		$scope.current = 0;
		$scope.results = [];
		$scope.result = {};
	}

	$scope.toggleAnswer = function (questionId, answerId) {
		if(!$scope.results[questionId]) {
			$scope.results[questionId] = [];
		}

		var idx = $scope.results[questionId].indexOf(answerId);
		if (idx > -1) {
		  $scope.results[questionId].splice(idx, 1);
		}
		else {
		  $scope.results[questionId].push(answerId);
		}
	};

	$scope.$watch('isConfirm', function(v){
		if(v === true) {
			$scope.state = $scope.STATE_RESULT;
		}
	})
}]);