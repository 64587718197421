angular.module('myApp').factory('Search', ['$http', '$q', function($http, $q) {
    var pageSize = 5;
    var emptyData = {
        data: [],
        _meta: {}
    };

    var Search = function() {};

    Search.get = function(q) {
        var def = $q.defer();

        var query = '/search/get';
        var params = {q: q, limit: pageSize};

        $http.get(query, {params: params}).then(function(resp) {
            if (resp.status !== 200 || !resp.data.success) {
                def.resolve(emptyData);
            } else {
                def.resolve({
                    data: resp.data.data,
                    _meta: resp.data._meta
                });
            }
        }, function() {
            def.resolve(emptyData);
        });

        return def.promise;
    };

    return Search;

}]);