angular.module('myApp')
.directive('imageWithCopyright', ['$timeout', '$window', function ($timeout, $window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            function doDomStuff() {
                $timeout(function () {
                    var elementImage = element.find('img')[0],
                        elementCopyright = element.find('figcaption'),
                        widthImage = 0;

                    if (elementImage) {
                        widthImage = parseInt($window.getComputedStyle(elementImage).width, 10);
                    }

                    if (widthImage) {
                        elementCopyright ? elementCopyright.outerWidth(widthImage) : null;
                    } else {
                        doDomStuff();
                    }
                }, 100);
            }

            doDomStuff();

            $window.addEventListener('resize', doDomStuff);
        }
    }
}]);