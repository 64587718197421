/*Слайдер статья старт*/
angular.module('myApp').controller('SubscribeSocialsCtrl',['$scope', '$timeout', '$window', function ($scope, $timeout, $window) {
    $scope.current = 0;

    if ($window.hasOwnProperty('FB')) {
        FB.init({
            appId      : 'facebook-jssdk',
            status     : true,
            xfbml      : true,
            version    : 'v2.8'
          });
    }

    $timeout(function(){
        // $window.instgrm.Embeds.process();
    }, 0)
}]);
