'use strict';
//быстрый способ решить проблему с лонгридами со стороны клиента
$('*').on('click','a',function(e){
	if (($(this).attr("href") || '').indexOf('/spec/') != -1 && !$(this).attr("target")) {
		$(this).attr("target",'_self');
    };
});
//быстрый способ решить проблему с лонгридами со стороны клиента
angular.module('myApp', [
    'ngRoute',
    'ngTouch',
    'duScroll',
    'angular-jplayer',
    'ngAnimate'
]);
