angular.module('myApp').provider('Page', function() {

    /**
     * Типы страниц
     * @type {{MAIN: string, SECTION: string, TAG: string, ARTICLE: string, SEARCH: string, AUTHOR: string, NEWS: string}}
     */
    var types = {
        MAIN: 'main',
        SECTION: 'section',
        TAG: 'tag',
        ARTICLE: 'article',
        AUTHOR: 'author',
        SEARCH: 'search',
        EMAIL_VERIFICATION_SUCCESS: 'EMAIL_VERIFICATION_SUCCESS',
        NEWS: 'news',
        EMAIL_UNSUBSCRIBE: 'EMAIL_UNSUBSCRIBE'
    };

    /**
     * Типы страниц со списками
     *
     * @type {*[]}
     */
    var listTypes = [
        types.MAIN,
        types.SECTION,
        types.TAG,
        types.SEARCH,
        types.AUTHOR,
        types.NEWS
    ];

    /**
     * Типы страниц с каким-то одним основным контентов
     * @type {*[]}
     */
    var singleTypes = [
        types.ARTICLE
    ];

    var Page = function() {};

    Page.isSingle = function(type) {
        return singleTypes.indexOf(type) > -1;
    };

    Page.isList = function(type) {
        return listTypes.indexOf(type) > -1;
    };

    Page.isSearch = function(type) {
        return type == types.SEARCH;
    };

    Page.isNews = function(type) {
        return type == types.NEWS;
    };

    Page.isMain = function(type) {
        return type == types.MAIN;
    };

    Page.isEmailVerificationSuccess = function(type) {
        return type == types.EMAIL_VERIFICATION_SUCCESS;
    };

    Page.isEmailUnsubscribe = function(type) {
        return type == types.EMAIL_UNSUBSCRIBE;
    }

    return {
        types: types,
        $get: function() {
            return Page;
        }
    };

});
