angular.module('myApp').controller('MemoryCtrl', ['$scope', '$interval', function($scope, $interval){
    function number2numeral(number, titles)
    {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }

    $scope.isRetina = function() {
        return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    };

    $scope.data = {
        "timer": {
            "seconds": 90,
            "parts": [60, 30]
        },
        "cards": [
            {
                "id": 1,
                "title": "Бейсджампинг",
                "description": "Прыжки с парашютом со скал, стен, антенн и мостов – один из самых опасных видов экстремального спорта. Благодаря своему рельефу Кавказ вполне может стать Меккой для бейсеров. Они уже нашли подходящие вершины в Северной Осетии (гора Кариухох), Кабардино-Балкарии (Ликоран, Тещины Зубы) и Дагестане (Ерыдаг, водопад Тобот). А в Джейрахском районе Ингушетии с 2013 года проходят международные фестивали по бейсджампингу. Глава республики Юнус-Бек Евкуров, бывший десантник, обещал подарить квартиру первому ингушскому бейсеру. Но такого пока нет, ведь для подготовки бейсджампера нужно не менее сотни прыжков с самолета.",
                "imageUrl": "1.png",
                "wideImageUrl": "1.png"
            },
            {
                "id": 2,
                "title": "Кайтинг",
                "description": "Кататься на воздушном змее лучше всего по морю, поэтому на базе парусной школы в Дагестане открыто отделение кайтборда. У любителей кайтсерфинга есть станция на Загородном пляже под Махачкалой. А зимой они пробуют свои силы в сноукайтинге на заснеженных склонах в Акушинском районе Дагестана.",
                "imageUrl": "2.png",
                "wideImageUrl": "2.png"
            },
            {
                "id": 3,
                "title": "Рафтинг",
                "description": "Сплав по горным рекам и искусственным гребным каналам на надувных плотах – рафтах – довольно рискованное занятие. Российские рафтеры разведали несколько сравнительно безопасных трасс для массового туризма на реках Кара-Койсу в Дагестане, Аргун в Чечне, Урух в Северной Осетии, Баксан в Кабардино-Балкарии. А на реке Большой Зеленчук в Карачаево-Черкесии проводятся не только коммерческие, но и спортивные сплавы.",
                "imageUrl": "3.png",
                "wideImageUrl": "3.png"
            },
            {
                "id": 4,
                "title": "Спелеотуризм",
                "description": "Исследователи пещер не первый год приезжают в Карачаево-Черкесию, где находятся несколько известных пещерных систем и самая глубокая (900 метров) пещера на территории Российской Федерации – Горло Барлога. Ее протяженность более трех километров, но до конца она еще не исследована. Интересные пещеры есть и в Баксанском ущелье Кабардино-Балкарии. А близ дагестанского селения Хустиль можно посетить удивительную подземную мечеть в пещере Дюрк.",
                "imageUrl": "4.png",
                "wideImageUrl": "4.png"
            },
            {
                "id": 5,
                "title": "Горнолыжный спорт",
                "description": "Помимо знаменитых круглогодичных трасс Эльбруса любителей горных лыж и сноуборда ждут курорт Чегет и трассы Адыл-Су и Безенги в Кабардино-Балкарии, Архыз и Домбай в Карачаево-Черкесии, Чиндирчеро в Дагестане, Армхи в Ингушетии, Цей в Северной Осетии, Ведучи в Чечне.",
                "imageUrl": "5.png",
                "wideImageUrl": "5.png"
            },
            {
                "id": 6,
                "title": "Джиппинг",
                "description": "Для любителей путешествовать на автомобилях по бездорожью Северный Кавказ подходит как нельзя лучше. Здесь есть все необходимое: интересные туристические маршруты, прекрасные виды и очень пересеченная местность. Турфирмы предлагают джип-туры по горам, ущельям и альпийским лугам, мимо озер, водопадов, пещер и каньонов.",
                "imageUrl": "6.png",
                "wideImageUrl": "6.png"
            },
            {
                "id": 7,
                "title": "Лонгборд-даунхилл",
                "description": "Скоростные спуски на роликовых досках по асфальту становятся все более популярны в окрестностях Машука в Ставропольском крае. Тут уже проводятся соревнования и лагеря с участием местных и приезжих экстремалов. А весной 2015 года на крутых серпантинах от Долины Нарзанов до Джилы-Су снимался первый эпизод российского документального фильма «Downhill Adventure» (студия Odifilm), премьера которого запланирована на весну 2016 года.",
                "imageUrl": "7.png",
                "wideImageUrl": "7.png"
            },
            {
                "id": 8,
                "title": "Дайвинг",
                "description": "Любителям подводного плавания со специальным снаряжением можно порекомендовать дайвинг-центр «Голубое озеро» в Кабардино-Балкарии. Глубина озера достигает 250 метров. Опытные ныряльщики могут погружаться в рамках любой квалификации от Open WaterDiver до Trimix Diver. А для новичков проводятся ознакомительные погружения в сопровождении инструктора. На северокавказском побережье Каспия дайвинг, к сожалению, развит слабо, зато в Дагестане есть большое сообщество любителей подводной охоты.",
                "imageUrl": "8.png",
                "wideImageUrl": "8.png"
            },
            {
                "id": 9,
                "title": "Парапланеризм",
                "description": "Полеты на безмоторных планерах популярны во всем мире, и Кавказ не исключение. Клубы парапланеристов существуют в Кабардино-Балкарии, Карачаево-Черкесии, Дагестане, на Ставрополье. В Домбае даже проводились Кубки России. Сейчас пилоты соревнуются в Чегеме и на горе Юца у Пятигорска. А в дагестанском городе Избербаш с 2001 года проводятся фестивали парапланеристов, куда приезжают десятки участников со всей России. Организаторы фестиваля учредили специальную медаль «Бриллиантовый Сапсан», которую получит тот, кто сумеет пролететь 100 километров.",
                "imageUrl": "9.png",
                "wideImageUrl": "9.png"
            }
        ]
    };

    $scope.timer = {
        states: {
            INITIALIZED: 'initialized',
            TICKING: 'ticking',
            PAUSED: 'paused',
            STOPPED: 'stopper'
        },
        init: function() {
            this._state = this.states.INITIALIZED;
            this.seconds = this.initialSeconds = $scope.data.timer.seconds || 90;
            this._milliseconds = this.seconds * 1000;
            this.position = 100;
            this.levels = $scope.data.timer.parts || [60, 30];
            this._statement = null;
        },
        start: function() {
            if(!this.isStopped()) {
                this._state = this.states.TICKING;
                this._ms = (new Date()).getTime();
                $interval.cancel(this._statement);
                this._statement = $interval(function(){
                    $scope.timer._ms = (new Date()).getTime();
                    $scope.timer._milliseconds -= 1000;
                    $scope.timer.seconds = Math.round($scope.timer._milliseconds / 1000);
                    $scope.timer.position = $scope.timer.seconds / $scope.timer.initialSeconds * 100;

                    if($scope.timer.seconds <= 0) {
                        $scope.timer.seconds = 0;
                        $scope.timer.stop();
                    }
                }, 1000);
            }
        },
        pause: function() {
            $interval.cancel(this._statement);
            this.statement = null;
            this._state = this.states.PAUSED;
            this._milliseconds -= (new Date()).getTime() - this._ms;
            this.seconds = Math.round(this._milliseconds / 1000);
            this.position = this.seconds / this.initialSeconds * 100;
        },
        stop: function() {
            this.pause();
            this._state = this.states.STOPPED;
            $scope.$broadcast('memory.the-end', {seconds: this.initialSeconds - this.seconds});
        },
        toggle: function() {
            if(this._state == this.states.PAUSED) {
                this.start();
            } else if(this._state == this.states.TICKING) {
                this.pause();
            }
        },
        isPaused : function() {
            return this._state == this.states.PAUSED;
        },
        isStopped : function(){
            return this._state == this.states.STOPPED;
        },
        isTicking: function(){
            return this._state == this.states.TICKING;
        }
    };

    $scope.pick = function(card){
        var opened = _.where($scope.cards, {isOpened: true});

        // запускаем таймер, если игра не начиналась
        if(!$scope.timer.isTicking() && !$scope.timer.isStopped()) {
            $scope.timer.start();
        }

        if(!$scope.timer.isStopped()) {
            if(opened.length == 0) { // нет открытых - открываем
                card.isOpened = true;
            } else if(opened.length == 1 && !card.isOpened && !card.isFinded){ // если выбрана вторая карта
                card.isOpened = true;
                if(opened[0].id == card.id) { // если карты совпали - помечаем как найденные
                    opened[0].isFinded = card.isFinded = true;
                    opened[0].isOpened = card.isOpened = false;
                    $scope.info.unshift(card);
                } else { // если не совпали, то закроем с небольшой задержкой, чтобы пользователь успел запомнить
                    $interval(function(){
                        var opened = _.where($scope.cards, {isOpened: true});
                        opened[0].isOpened = opened[1].isOpened = false;
                    }, 500, 1)
                }
            }

            // если все карточки отгаданы - останавливаем таймер
            var finded = _.where($scope.cards, {isFinded: true});
            if(finded.length == $scope.cards.length) {
                $scope.timer.stop();
            }
        }
    }

    // инициализация и точка входа в игру
    $scope.tryYet = function(){
        var cards = angular.copy($scope.data.cards);
        $scope.cards = _.shuffle(cards.concat(angular.copy(cards)));
        $scope.info = [];
        $scope.result = null;
        $scope.timer.init();
    }

    // обработчик остановки таймера (либо закончилось время, либо отгаданы все карточки)
    $scope.$on('memory.the-end', function(e, data){
        if(!$scope.timer.seconds) {
            $scope.result = {
                title : 'Вы не успели',
                isSuccess : false
            }
        } else {
            $scope.result = {
                title : 'Вы открыли все карточки за ' + data.seconds + ' '
                    + number2numeral(data.seconds, ['секунда', 'секунды', 'секунд']) ,
                isSuccess : true
            }
        }
    });

    window.addEventListener("resize", function(event) {
        if(window.innerWidth != $scope.displayWidth && $scope.timer.isTicking()) {
            $scope.displayWidth = window.innerWidth;
            $scope.timer.pause();
            $scope.$digest();
        }
    }, false);

    $scope.tryYet();
}]);