angular.module('myApp').factory('Footer', ['$http', '$q', function($http, $q) {
    var Footer = function() {};

    Footer.TYPE_DEFAULT = 0;
    Footer.TYPE_MAIN = 1;
    Footer.TYPE_SECTION = 2;
    Footer.TYPE_TAG = 3;
    Footer.TYPE_ARTICLE = 4;

    Footer.load = function(params) {
        var def = $q.defer();

        var query = '/footer/view';

        $http.get(query, {params: params}).then(function(resp) {
            if (resp.status !== 200 || !resp.data) {
                def.resolve({success: false});
            } else {
                def.resolve({
                    success: true,
                    data: resp.data
                });
            }
        }, function() {
            def.resolve({success: false});
        });

        return def.promise;
    };

    return Footer;

}]);