/*Слайдер статья старт*/
angular.module('myApp').controller('tmSlider',['$scope','$element','$timeout', function ($scope,$element,$timeout) {
    $scope.curItem=0;
    $scope.lengthArray=0;
    $scope.next = function () {
        if ($scope.curItem == $scope.lengthArray -1) {
            $scope.curItem=0;
        }else{
            $scope.curItem++;
        };
    };
    $scope.prev = function () {
        if ($scope.curItem == 0) {
            $scope.curItem=$scope.lengthArray -1;
        }else{
            $scope.curItem--;
        };
    };
}]);