/**
 * Сервис для работы с тестами
 */
angular.module('myApp').factory('Test', ['$http', '$q', function($http, $q) {

    var emptyData = {
    };

    var Test = function() {};

    /**
     * Получение списка новостей
     *
     * @param lastTime
     * @returns {*}
     */
    Test.config = function(id) {
        var def = $q.defer();

        var query = '/tests/' + id + '/config';

        $http.get(query).then(function(resp) {
            if (resp.status !== 200) {
                def.resolve(emptyData);
            } else {
                def.resolve(resp.data.data);
            }
        }, function() {
            def.resolve(emptyData);
        });

        return def.promise;
    };

    Test.result = function(id, results) {
        var def = $q.defer();

        var query = '/tests/' + id + '/result';

        $http.post(query, results).then(function(resp) {
            if (resp.status !== 200) {
                def.resolve(emptyData);
            } else {
                def.resolve(resp.data.data);
            }
        }, function() {
            def.resolve(emptyData);
        });

        return def.promise;
    };

    Test.finish = function(id) {
        var def = $q.defer();

        var query = '/tests/' + id + '/finish';

        $http.get(query).then(function(resp) {
            if (resp.status !== 200) {
                def.resolve(emptyData);
            } else {
                def.resolve(resp.data.data);
            }
        }, function() {
            def.resolve(emptyData);
        });

        return def.promise;
    };

    return Test;

}]);