angular.module('myApp').factory('HttpInterceptor', ['$q', function($q) {
    return {
        responseError: function(rejection) {
            if (rejection.status >= 500) {
                window.location.reload();
            }

            if (rejection.status === 404) {
                return {
                    transformRequest: [],
                    transformResponse: [],
                    method: 'GET',
                    url: rejection.config.url,
                    headers: rejection.config.headers,
                    data: rejection.data
                };
            }

            return $q.reject(rejection);
         }
     };
}]);