/**
 * Отдаем $state в $rootScope
 */
angular.module('myApp').run(['$rootScope', '$route', function($rootScope, $route) {
    $rootScope.$route = $route;

    $rootScope.getRouteName = function() {
        if ($rootScope.$route.current) {
            return $rootScope.$route.current.$$route.originalPath;
        }

        return '';
    };
}]);