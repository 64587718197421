angular.module('myApp')
.directive('smi2', ['$window', function($window){
    return {
        restrict: 'C',
        link: function(scope, element, attrs){
            scope.$watch(function(){
                    return element.find('.sk_item_83854').length;
                },
                recalc);

            $window.onresize = function(){ recalc(element.find('.sk_item_83854').length) };

            scope.index = 0;

            scope.sliderLeft = function(){
                if(scope.isMobile()) {
                    if(++scope.index == scope.count) scope.index = 0;
                    element.find('.sk_items_83854').css('margin-left', -scope.index * 100 + '%');
                }
            };

            scope.sliderRight = function(){
                if(scope.isMobile()) {
                    if(--scope.index < 0) scope.index = scope.count - 1;
                    element.find('.sk_items_83854').css('margin-left', -scope.index * 100 + '%');
                }
            };

            scope.isMobile = function(){
                return $window.innerWidth < 768;
            };

            function recalc(v){
                if(scope.isMobile()) {
                    scope.count = v;
                    scope.range = _.range(v);
                    element.find('.sk_items_83854').css('width', v * 100 + '%')
                    element.find('.sk_item_83854').css('width', 100/v + '%')
                } else {
                    element.find('.sk_items_83854').css('margin-left', '');
                    element.find('.sk_items_83854').css('width', '');
                    element.find('.sk_item_83854').css('width', '')
                }
            }
        }
    }
}]);