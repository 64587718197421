angular.module('myApp').controller('SocialCtrl', ['$scope', '$location', '$http', function($scope, $location, $http){
    /**
     * Счетчик лайков
     *
     * @type {{vk: number, fb: number, tw: number, ok: number}}
     */
    $scope.shareCounters = {
        vk : 0,
        fb: 0,
        tw: 0,
        ok: 0
    };

    $scope.isOpened = false;

    /**
     * Обновление социальных мета
     */
    $scope.updateSocialMeta = function(){
        $scope.socialMeta = {
            title : angular.element('meta[property="og:title"]:last').attr('content') || 'Это Кавказ',
            description : angular.element('meta[property="og:description"]:last').attr('content') || 'Тысячелетняя история, вековые традиций, суровая и одновременно притягательная природа. Все это Кавказ, который гостеприимно встретит каждого, кому интересно все новое и необычное.',
            imageOG : angular.element('meta[property="og:image"]:last').attr('content') || '',
            image : angular.element('link[rel="image_src"]:last').attr('href') || '',
            url : angular.element('meta[property="og:url"]:last').attr('content') || window.location.href
        };
    };

    /**
     * Создание социальной ссылки
     *
     * @param type
     * @param title
     * @param description
     * @param image
     * @param url
     */
    $scope.socialLinks = function (type, title, description, image, url) {
        $scope.updateSocialMeta();

        var createdURL;
        title = title || $scope.socialMeta.title;
        description = description || $scope.socialMeta.description;
        url = url || $scope.socialMeta.url;

        if (type == 'vk') {
            if (image=="") {
                image=$scope.socialMeta.image;
            }
            image = image || $scope.socialMeta.image;
            createdURL = '//vk.com/share.php?title='+encodeURIComponent(title) +
                '&description='+encodeURIComponent(description) +
                '&image='+encodeURIComponent(image) +
                '&url='+encodeURIComponent(url) +
                '&noparse=true';
            window.open(createdURL,'','toolbar=0,status=0,width=626,height=436');
            $scope.shareCounters.vk++;
        }

        if(type == 'ok') {
            createdURL  = '//connect.ok.ru/offer?';
            createdURL += 'title=' + encodeURIComponent(description);
            createdURL += '&url='    + encodeURIComponent(url);
            window.open(createdURL,'','toolbar=0,status=0,width=626,height=436');
            $scope.shareCounters.ok++;
        }

        if(type == 'gp') {
            createdURL = '//plus.google.com/share?';
            createdURL += 'url=' + encodeURIComponent(url);
            window.open(createdURL,'','toolbar=0,status=0,width=626,height=436');
        }
    };

    window.VK =  window.VK || {};
    window.VK.Share = {
        count: function(v1, v2) {
            $scope.shareCounters.vk = +v2;
        }
    };

    window.ODKL = window.ODKL || {};
    window.ODKL.updateCount = function(v1, v2) {
        $scope.shareCounters.ok = +v2;
    };

    /**
     * Подгрузка количества шарингов
     *
     * @param type
     */
    $scope.loadShareCount = function(type){
        var url = $location.absUrl();

        switch(type) {
            case 'vk':
                $http({
                    method: 'JSONP',
                    url : 'https://vk.com/share.php?act=count&index=1&url=' + encodeURIComponent(url) + '&callback=JSON_CALLBACK'
                })
                    .success(function(response) {});
                break;
            case 'ok':
                $http({
                    method: 'JSONP',
                    url: '//ok.ru/dk?st.cmd=extLike&uid=odklcnt0&ref=' + encodeURIComponent(url) + '&callback=JSON_CALLBACK'
                })
                    .success(function(response) {
                    });
                break;
            case 'all':
                $scope.loadShareCount('vk');
                $scope.loadShareCount('fb');
                $scope.loadShareCount('ok');
                break;
        }
    };

    $scope.$on('$routeChangeSuccess', function(e, current) {
        if($scope.withCounters == true){
            $scope.loadShareCount('all');
        }
    });

    $(window).focus(function() {
        if($('.b-page-news').length) {
            $scope.loadShareCount('all');
        }
    });

    $scope.updateSocialMeta();

    if($scope.withCounters == true){
        $scope.loadShareCount('all');
    }
}]);
