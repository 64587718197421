angular.module('myApp').controller('SplitPhotoCtrl', ['$scope', '$element', function($scope, $element){
    defineParams();

    $scope.isDrag = false; 
    $scope.isScrolled = false;
    $scope.delimiterSize = $($element).find('.b-split-photo__delimiter').width();
    $scope.blockSize = $($element).width();
    $scope.position = $scope.device.isDesktop
        ? 20 
        : $scope.device.isMobile
            ? 90 
            : 50;
    $scope.offsetBorder = $scope.device.isMobile 
        ? 10
        : 0;
    
    $scope.move = function($event, isClick) {
        if (($scope.isDrag && !isClick) || (isClick)) {
            if ($event.offsetX < $scope.delimiterSize) {
                $scope.position = 0;
            } else if ($event.offsetX > $scope.blockSize - $scope.delimiterSize) {
                $scope.position = 100;
            } else {
                $scope.position = $event.offsetX / $scope.blockSize * 100;
            };

            checkBorders();
        };
    }

    $scope.out = function() {
        $scope.isDrag = false;
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        } else { // старый IE
          document.selection.empty();
        }
    }

    $scope.toLeft = function(){
        $scope.position = 0;
        if($scope.device.isMobile) {
            checkBorders();
        }
    }

    $scope.toRight = function(){
        $scope.position = 100;
        if($scope.device.isMobile) {
            checkBorders();
        }
    }

    $(window).resize(defineParams)

    $(window).scroll(function(){
        var middleElementTop = $($element).offset().top + $($element).height() / 2;
        if(middleElementTop < $(window).scrollTop() + $(window).height()
            && middleElementTop > $(window).scrollTop()) {
            if($scope.device.isDesktop && !$scope.isScrolled) {
                $scope.position = 50;
            }
            
            $scope.isScrolled = true;
        }
    });

    function checkBorders() {
        if($scope.device.isMobile) {
            if($scope.position < $scope.offsetBorder) { $scope.position = $scope.offsetBorder }
                if($scope.position > 100 - $scope.offsetBorder) { $scope.position = 100 - $scope.offsetBorder }
        }
    }

    function defineParams(){
        $scope.blockSize = $($element).outerWidth(true);
        $scope.device = {
            isDesktop: $(window).width() > 1024,
            isTablet: $(window).width() >= 768 && $(window).width() < 1024,
            isMobile: $(window).width() <= 767
        }
    }
}]);