/**
 * Конфигурация роутов
 */
angular.module('myApp').config([
'$locationProvider', '$routeProvider', '$httpProvider', 'PageProvider', 'ListStateProvider', '$compileProvider',
function($locationProvider, $routeProvider, $httpProvider, PageProvider, ListStateProvider, $compileProvider) {
    $locationProvider.html5Mode(true);

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|whatsapp):/);

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    $httpProvider.interceptors.push('HttpInterceptor');

    $routeProvider
        // Секция
        .when('/sections/:section', {
            templateUrl: function(params) {
                var query = {
                    short: 1
                };

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                return '/sections/' + params.section + '?' + $.param(query);
            },
            type: PageProvider.types.SECTION
        })

        // автор
        .when('/author/:id', {
            templateUrl: function(params) {
                var query = {
                    short: 1
                };

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                return '/author/' + params.id + '?' + $.param(query);
            },
            type: PageProvider.types.AUTHOR
        })

        // Разводящая тегов
        .when('/tags', {
            templateUrl: '/tags?short=1',
            type: PageProvider.types.TAG
        })

        // 404
        .when('/error/:code', {
            templateUrl: function(params) {
                return '/error/' + params.code + '?short=1';
            }
        })

        .when('/search', {
            redirectTo: '/'
        })

        // Новости
        .when('/news', {
            templateUrl: function() {
                var query = {
                    short: 1
                };

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                return '/news?' + $.param(query);
            },
            type: PageProvider.types.NEWS
        })

        .when('/unsubscribe/:hash', {
            templateUrl: function (params) {
                return '/unsubscribe/' + params.hash + '?short=1';
            },
            type: PageProvider.types.EMAIL_UNSUBSCRIBE
        })

        // Тег
        .when('/:slug', {
            templateUrl: function(params) {
                var query = {
                    short: 1
                };

                if (window.rubricLimit) {
                    query['limit'] = window.rubricLimit;
                }

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                return '/' + params.slug + '?' + $.param(query);
            },
            type: PageProvider.types.TAG
        })

        // Поиск
        .when('/search/:q*', {
            templateUrl: function(params) {
                var query = {
                    short: 1
                };

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                var search = location.search ? location.search + '&' : '?';
                search += $.param(query);

                return '/search/' + encodeURIComponent(params.q) + search;
            },
            type: PageProvider.types.SEARCH
        })

        // Тесты
        .when('/tests/:id', {
            templateUrl: function(params) {
                return '/tests/' + params.id + '?short=1';
            },
            type: PageProvider.types.ARTICLE
        })

        // Тесты
        .when('/tests/:id/confirm', {
            templateUrl: function(params) {
                return '/tests/' + params.id + '/confirm?short=1';
            },
            type: PageProvider.types.ARTICLE
        })

        // Подтверждение подписки
        .when('/subscribe/success', {
            templateUrl: function() {
                return '/subscribe/success?short=1';
            },
            type: PageProvider.types.EMAIL_VERIFICATION_SUCCESS
        })


        // Статья
        .when('/:tag/:article', {
            templateUrl: function(params) {
                var query = angular.extend({short: 1}, params);
                delete query.tag;
                delete query.article;

                return '/' + params.tag + '/' + params.article + '?' + $.param(query);
            },
            type: PageProvider.types.ARTICLE
        })

        // Главная
        .when('/', {
            templateUrl: function() {
                var query = {
                    short: 1
                };

                if (ListStateProvider.state.page > 0) {
                    query['limit'] = ListStateProvider.state.page;
                }

                return '/site/index?' + $.param(query);
            },
            type: PageProvider.types.MAIN
        })

        .otherwise({
            templateUrl: '/error/404?short=1'
        });
}]);