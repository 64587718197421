/**
 * Контроллер директивы LoadMore
 */
angular.module('myApp').controller('LoadMoreCtrl', [
'$scope', '$element', '$http', 'ListState',
function($scope, $element, $http, ListState) {

    $scope.pageSize = $scope.pageSize ? parseInt($scope.pageSize) : 10;
    $scope.currentOffset = $scope.currentOffset ? parseInt($scope.currentOffset) : 0;
    $scope.count = parseInt($scope.count);
    $scope.offset = $scope.currentOffset ? $scope.currentOffset : $scope.pageSize;
    $scope.params = $scope.params ? $scope.params : {};

    /**
     * Проверка, нужно ли скрывать кнопку
     *
     * @returns {boolean}
     */
    $scope.needHide = function() {
        return $scope.offset >= $scope.count;
    };

    /**
     * Переключаем видимость элемента
     */
    $scope.toggleElement = function() {
        if ($scope.needHide()) {
            $element.addClass('hidden');
        } else {
            $element.removeClass('hidden');
        }
    };

    /**
     * Подгрузка
     */
    $scope.loadMore = function() {
        if ($element.hasClass('load')) {
            return false;
        }

        $element.addClass('load');
        var params = $scope.getParams();

        $http.get($scope.url, {params: params})
            .success(function(data) {
                $($scope.container).append(data);
                $scope.offset += $scope.pageSize;

                $scope.saveState();
            }).finally(function() {
                $element.removeClass('load');
                $scope.toggleElement();
            });
    };

    /**
     * Получение параметров для запроса
     */
    $scope.getParams = function() {
        var innerParams = {
            limit: $scope.pageSize,
            offset: $scope.offset,
            short: true
        };
        
        var lastElement = $($scope.container).find('.load-more-item').last();
        innerParams.lastId = parseInt(lastElement.attr('data-id'));
        innerParams.lastTime = parseInt(lastElement.attr('data-time'));

        return angular.extend(innerParams, $scope.params);
    };

    /**
     * Сохранение состояния списка
     */
    $scope.saveState = function() {
        ListState.setPage($scope.offset);
    };

    // Подписка на клик по кнопке
    $element.on('click', $scope.loadMore);

    $scope.toggleElement();

}]);