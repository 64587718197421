/**
 * Директива для кнопки шаринга
 */
angular.module('myApp').directive('share', function() {

    var link = function(scope, elm) {
        $(elm).on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            var width = 510;
            var height = 460;
            var left = (screen.width - width) / 2;
            var top = (screen.height - height) / 2;
            var options = "height=" + height + ",width=" + width
                        + ",left=" + left + ",top=" + top
                        + ",scrollbars=1,location=no";

            window.open(scope.href, '', options);
        });

    };

    return {
        restrict: 'A',
        scope: {
            href: '@share'
        },
        link: link
    };

});