angular.module('myApp').controller('SlideshowCtrl', ['$scope', '$window', '$element', function ($scope, $window, $element) {
    $scope.current = 0;
    $scope.count = 0;
    $scope.slidesByType = [];
    $scope.isSrolled = false;
    $scope.isFullscreen = false;
    $scope.isDesktop = false;

    $scope.next = function () {
        if($scope.isSrolled) goUpSlide();
        if ($scope.current == $scope.count - 1) {
            $scope.current = 0;
        } else {
            $scope.current++;
        };
    };

    $scope.prev = function () {
        if($scope.isSrolled) goUpSlide();
        if ($scope.current == 0) {
            $scope.current = $scope.count - 1;
        } else {
            $scope.current--;
        };
    };

    function goUpSlide(){
        $("html, body").stop().animate({scrollTop:$element.offset().top}, '500');
    }

    angular.element($window).bind('scroll', function(e) {
        var scrollTop = angular.element($window).scrollTop(),
            slideshowBottom = $element.offset().top + $element.innerHeight(),
            navigationTop = $element.offset().top + $element.innerHeight() / 2;

        $scope.isSrolled = (scrollTop >= navigationTop && scrollTop <= slideshowBottom) && !$scope.isFullscreen;
        $scope.$apply();
    });

    angular.element($window).bind('resize', function(e) {
        $scope.isDesktop = $window.innerWidth>= 1024;
        $scope.$apply();
    });

    $scope.$watch('isDesktop', function(v) {
        $scope.isFullscreen &= v;
    });

    $scope.$watch('isFullscreen', function(v) {
        angular.element('body').css('overflow', v ? 'hidden' : 'auto');
    })
}]);