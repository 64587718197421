angular.module('myApp').controller('PlaybuzzCtrl',['$scope', '$q', '$timeout', function ($scope, $q, $timeout) {
    $scope.playbuzzReady = false;
    $scope.playbuzzScript = '';
    
    $scope.setScriptUrl = function(url) {
        $scope.playbuzzScript = url;
        
        $scope.renderFeed();
    }
    
    $scope.renderFeed = function() {
        $scope.loadPlaybuzzEnv().then(function() {
            $timeout(function() {
                if (PlayBuzz) {
                    PlayBuzz.Feed.renderFeed();
                }
            }, 1000);
        });
    };
    
    $scope.loadPlaybuzzEnv = function() {
        var def = $q.defer();
        
        if ($scope.playbuzzReady) {
            def.resolve();
        } else {
            $.getScript($scope.playbuzzScript).done(function() {
                def.resolve();
            }).fail(function() {
                def.reject();
            });
        
            $scope.playbuzzReady = true;
        }
        
        return def.promise;
    };
}]);